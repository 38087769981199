import { utils } from "./utils";
// add inputs until we reach or surpass the target value (or deplete)
// worst-case: O(n)
export function accumulative(utxos, outputs, feeRate, type) {
    if (!isFinite(utils.uintOrNaN(feeRate)))
        return null;
    let bytesAccum = utils.transactionBytes([], outputs, type);
    let inAccum = 0;
    const inputs = [];
    const outAccum = utils.sumOrNaN(outputs);
    for (let i = 0; i < utxos.length; ++i) {
        const utxo = utxos[i];
        const utxoBytes = utils.inputBytes(utxo);
        const utxoFee = feeRate * utxoBytes;
        const utxoValue = utils.uintOrNaN(utxo.value);
        // skip detrimental input
        if (utxoFee > utxo.value) {
            if (i === utxos.length - 1)
                return { fee: feeRate * (bytesAccum + utxoBytes) };
            continue;
        }
        bytesAccum += utxoBytes;
        inAccum += utxoValue;
        inputs.push(utxo);
        const fee = feeRate * bytesAccum;
        // go again?
        if (inAccum < outAccum + fee)
            continue;
        return utils.finalize(inputs, outputs, feeRate, type);
    }
    return { fee: feeRate * bytesAccum };
}
